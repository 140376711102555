import { defineMessages } from 'react-intl'

/* eslint-disable max-len */

export default defineMessages({
  valueBoxSubtitle: 'Modern software development brings you and your company further. Lay the foundation for the future today.',
  valueBoxTitle1: 'Digital transformation',
  valueBoxTitle2: 'Software audit',
  valueBoxTitle3: 'Web application development',
  strapiTitle: 'Modern web frontends are the leading systems',
  strapiText: 'The times of proprietary desktop software are over. So are mutually limiting system components. A good frontend (the visible and usable tool) no longer has to follow the structure of old data sources. It acts independently and flexibly. Flexibility in the selection of its product and data sources allows you to act faster and prevents the compulsive binding to other systems.{br}{br}You too can benefit from the approaches of the latest software development. You would be surprised which potentials are still unused in your company.',
  heroTitle: '<b>Software development</b> as the key to <b>sustainable success</b>',
  heroSubtitle: 'Do not oversleep the digitization. Use modern web applications for increase your productivity and turnover.',
  descriptionText: 'Every company faces similar challenges. Internal processes should be digitized or, if already done, optimized. Sales platforms must react flexibly to the requirements of the market, always be stable and preferably highly scalable. However, adaptations often take too long and the software developed over years can hardly be understood.{br}{br}It is time to clean up. Flexible and adaptive systems paired with intuitive user guidance and attractive design are the future, whether for e - commerce platforms or B2B tools.{br}{br}Become independent. Become flexible. Do not miss the chance.',
  descriptionTitle: 'Outdated and unmaintainable software costs time, nerves and money',
  descriptionButton: 'I want to be advised free of charge',
  contactTitle: 'Future-proof with state-of-the-art <b>software development</b>',
  contactSubtitle: 'Do not oversleep the digitization. Use modern web applications for increase your productivity and turnover.',
  contactheadline: 'Get <b>free advice</b> on software development now.',
  benefitsText: 'Regardless of the target group (whether for internal processes or for external sales) the basic parameters for successful and modern software development are always the same.',
  benefitsTitle: 'What does a sustainable software solution have to offer to be a success<b>?</b>',
  benefitsItemA: 'Data must be connected flexibly and independently of the frontend.',
  benefitsItemB: 'The software must be modular and flexibly expandable.',
  benefitsItemC: 'The system architecture must be sustainable and load stable.',
  benefitsItemD: 'The frontend is the leading and independent system and does not have to adapt to existing Set up data structures in the background.',
  benefitsItemE: 'The design and conception process solves problems and optimizes processes.',
  benefitsItemF: 'System performance and user guidance must be outstanding.',
  benefitsItemG: 'The project must be treated as a steadily and controlled growing product',
  benefitsButton: 'I want to be advised free of charge'
})
